.skillsHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid black .5px;
  margin: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 60px;
  background: white;
  overflow: hidden;
}

.skillsBody {
  border: solid black .5px;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 75vh;
  display: flex;
  background: white;
}

.skillsHero {
  border-right: solid black .5px;
  flex: .9;
  justify-content: space-evenly;
  padding: 10px;
  background: white;
  overflow: hidden;
}

.skillsHeader > div:nth-child(1){
  position: relative;
  animation: animate 2s;
  animation-fill-mode: backwards;
}

.skillsHeader > div:nth-child(2){
  position: relative;
  animation: animate 1.75s;
  animation-fill-mode: backwards;
}

.skillsHeader > div:nth-child(3){
  position: relative;
  animation: animate 1.25s;
  animation-fill-mode: backwards;
}

.skillsHeader > div:nth-child(4){
  position: relative;
  animation: animate 1.5s;
  animation-fill-mode: backwards;
}

.skillsHeader > div:nth-child(5){
  position: relative;
  animation: animate 2.25s;
  animation-fill-mode: backwards;
}

.skillsHeader > div:nth-child(6){
  position: relative;
  animation: animate 1s;
  animation-fill-mode: backwards;
}

@keyframes animate{
  0%{
    bottom: -100px;
  }
  100%{
    bottom: 0;
  }
}

.row1 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding-left: 10px;
  margin: 10px;
}

.row2 {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
  padding-right: 10px;
  margin: 10px;
}

.row1 > div > img {
  width: 200px;
  height: auto;
}

.row2 > div > img {
  width: 200px;
  height: auto;
}

.row1 > .css3 > img {
  width: 142px;
  height: auto;
  padding-left: 10px;
}

.skillsNumber {
  display: flex;
  align-items: flex-end;
}

.skillsNumberChild {
  border: solid black .5px;
  background-color: white;
  min-height: 30px;
  min-width: 30px;
  margin-bottom: 100px;
  margin-left: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

@media (max-width: 900px) {
  .footerNavSkills {
    margin-bottom: 1px;
  }
  
.row1 {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding-left: 10px;
  margin: 10px;
}

.row2 {
  display: flex;
  flex-flow: column;
  justify-content: space-around;
  align-items: center;
  padding-right: 10px;
  margin: 10px;
}

  .row1 > div > img {
    width: 150px;
    height: auto;
  }
  
  .row2 > div > img {
    width: 150px;
    height: auto;
  }
  
  .row1 > .css3 > img {
    width: 92px;
    height: auto;
    padding-left: 10px;
  }
}