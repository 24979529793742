.socialHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid black .5px;
  margin: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 60px;
  background: white;
  overflow: hidden;
}

.socialHeader > div:nth-child(1) {
  position: relative;
  animation: animate 2s;
  animation-fill-mode: backwards;
}

.socialHeader > div:nth-child(2) {
  position: relative;
  animation: animate 1s;
  animation-fill-mode: backwards;
}

.socialHeader > div:nth-child(3) {
  position: relative;
  animation: animate 1.5s;
  animation-fill-mode: backwards;
}

.socialHeader > div:nth-child(4) {
  position: relative;
  animation: animate 1.25s;
  animation-fill-mode: backwards;
}

.socialHeader > div:nth-child(5) {
  position: relative;
  animation: animate 1.75s;
  animation-fill-mode: backwards;
}

@keyframes animate{
  0%{
    bottom: -100px;
  }
  100%{
    bottom: 0;
  }
}

.socialBody {
  border: solid black .5px;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 75vh;
  display: flex;
  background: white;
}

.socialHero {
  border-right: solid black .5px;
  flex: .9;
  display: flex;
  align-items: center;
  flex-direction: column;
  font-size: 1.75em;
  padding: 20px;
  background: white;
}

.spotifyContainer{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 20px;
}

.socialNumber {
  display: flex;
  align-items: flex-end;
}

.socialNumberChild {
  border: solid black .5px;
  background-color: white;
  min-height: 30px;
  min-width: 30px;
  margin-bottom: 30px;
  margin-left: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

@media (max-width: 900px) {
  .footerNavMusic {
    margin-bottom: 1px;
  }
}