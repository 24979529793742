.portfolioHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid black .5px;
  margin: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 60px;
  background: white;
  overflow: hidden;
}

.portfolioHeader > div:nth-child(1) {
  position: relative;
  animation: animate 2.5s;
  animation-fill-mode: backwards;
}

.portfolioHeader > div:nth-child(2) {
  position: relative;
  animation: animate 2.75s;
  animation-fill-mode: backwards;
}

.portfolioHeader > div:nth-child(3) {
  position: relative;
  animation: animate 1.75s;
  animation-fill-mode: backwards;
}

.portfolioHeader > div:nth-child(4) {
  position: relative;
  animation: animate 1.5s;
  animation-fill-mode: backwards;
}

.portfolioHeader > div:nth-child(5) {
  position: relative;
  animation: animate 1s;
  animation-fill-mode: backwards;
}

.portfolioHeader > div:nth-child(6) {
  position: relative;
  animation: animate 1.25s;
  animation-fill-mode: backwards;
}

.portfolioHeader > div:nth-child(7) {
  position: relative;
  animation: animate 2s;
  animation-fill-mode: backwards;
}

.portfolioHeader > div:nth-child(8) {
  position: relative;
  animation: animate 3s;
  animation-fill-mode: backwards;
}

.portfolioHeader > div:nth-child(9) {
  position: relative;
  animation: animate 2.25s;
  animation-fill-mode: backwards;
}

@keyframes animate{
  0%{
    bottom: -100px;
  }
  100%{
    bottom: 0;
  }
}

.portfolioBody {
  border: solid black .5px;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 75vh;
  display: flex;
  background: white;
}

.portfolioHero {
  border-right: solid black .5px;
  flex: .9;
  padding: 20px;
  background: white;
}

.portfolioHeroRow1{
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.projectOption > a > img {
  width: 400px;
  height: 200px
}

.portfolioHeroRow2{
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.portfolioNumber {
  display: flex;
  align-items: center;
}

.portfolioNumberChild {
  border: solid black .5px;
  background-color: white;
  min-height: 30px;
  min-width: 30px;
  margin-left: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

@media (max-width: 900px) {
  .footerNavProjects {
    margin-bottom: 1px;
  }
}

@media (max-width: 700px) {
  .projectOption > a > img {
    width: 200px;
    height: 100px;
  }
}