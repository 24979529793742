.contactHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid black .5px;
  margin: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 60px;
  background: white;
  overflow: hidden;
}

.contactHeader > div:nth-child(1) {
  position: relative;
  animation: animate 1s;
  animation-fill-mode: backwards;
}

.contactHeader > div:nth-child(2) {
  position: relative;
  animation: animate 1.5s;
  animation-fill-mode: backwards;
}

.contactHeader > div:nth-child(3) {
  position: relative;
  animation: animate 1.25s;
  animation-fill-mode: backwards;
}

.contactHeader > div:nth-child(4) {
  position: relative;
  animation: animate 2s;
  animation-fill-mode: backwards;
}

.contactHeader > div:nth-child(5) {
  position: relative;
  animation: animate 1.75s;
  animation-fill-mode: backwards;
}

.contactHeader > div:nth-child(6) {
  position: relative;
  animation: animate 2.5s;
  animation-fill-mode: backwards;
}

.contactHeader > div:nth-child(7) {
  position: relative;
  animation: animate 2.25s;
  animation-fill-mode: backwards;
}

@keyframes animate{
  0%{
    bottom: -100px;
  }
  100%{
    bottom: 0;
  }
}

.contactBody {
  border: solid black .5px;
  margin-left: 10px;
  margin-right: 10px;
  min-height: 75vh;
  display: flex;
  background: white;

}

.contactHero {
  border-right: solid black .5px;
  flex: .9;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  background: white;
}

.contactHeroHeader {
  display: flex;
  justify-content: center;
  font-size: 2em;
  margin-right: 10px;
  margin-left: 10px;
  padding: 5px;
}

.contactHeroForm {
  display: flex;
  justify-content: center;
  padding: 5px;
}

.contactHeroFormReply {
  display: flex;
  justify-content: center;
}

.contactHeroFormName  {
  margin-bottom: 10px;
}

.contactHeroFormName > input {
 width: 100%;
 line-height: 2em;
 outline: none;
 border: 1.5px solid rgb(236, 236, 236);
}

.contactHeroFormName > input:focus {
  border: 1.5px solid rgb(150, 150, 150);
}

.contactHeroFormEmail  {
  margin-bottom: 10px;
}

.contactHeroFormEmail > input {
 width: 100%;
 line-height: 2em;
 outline: none;
 border: 1.5px solid rgb(236, 236, 236);
}

.contactHeroFormEmail > input:focus {
  border: 1.5px solid rgb(150, 150, 150);
}

.contactHeroFormMessage  {
  margin-bottom: 10px;
}

.contactHeroFormMessage > textarea {
 outline: none;
 line-height: 2em;
 width: 100%;
 border: 1.5px solid rgb(236, 236, 236);
}

.contactHeroFormMessage > textarea:focus {
  border: 1.5px solid rgb(150, 150, 150);
}

.contactHeroFormButtonReplyContainer {
  display: flex;
  justify-content: center;
  margin-bottom: 10px;
}

.contactHeroFormButton > button{
  border-radius: 4px;
  background-color: #000;
  border: none;
  color: #FFFFFF;
  text-align: center;
  font-size: 1em;
  padding: 10px;
  margin-top: 10px;
  width: 100px;
  transition: all 0.5s;
  cursor: pointer;
}

.contactHeroFormButton > .button span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;
}

.contactHeroFormButton > .button span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.contactHeroFormButton > .button:hover span {
  padding-right: 25px;
}

.contactHeroFormButton > .button:hover span:after {
  opacity: 1;
  right: 0;
}

.contactHeroSocialLinks {
  display: flex;
  justify-content: center;
  align-items: center;
}

.contactHeroSocialLinks > div > a > img {
  width: 25px;
  height: auto;
  padding-left: 3vw;
  padding-right: 3vw;
}

.contactNumber {
  border: solid black .5px;
  background-color: white;
  max-height: 30px;
  min-width: 30px;
  margin-top: 100px;
  margin-left: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

@media (max-width: 700px) {
  .contactHeroFormName > input {
    width: 90%;
   }

   .contactHeroFormEmail > input {
    width: 90%;
   }

   .contactHeroFormMessage > textarea {
    width: 90%;
   }

  .recaptcha {
    transform:scale(0.75);
    transform-origin:0 0;
  }
  
}