.logoContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 95vh;
}

.logo {
  cursor: pointer;
  object-fit: contain;
}

.logo > img {
  width: 100%;
  max-width: 50vw;
  height: auto;
}