.menuOptions {
  flex: .2;
  font-size: 2em;
  border: solid black .5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px;
  height: 80vh;
  color: black;
  text-decoration: none;
  background: white;
  transition: all .2s;
  cursor: pointer;
}

.menuOptions:hover {
  background-color: black;
  color: white;
  cursor: pointer;
  filter: drop-shadow(2px 2px 2px grey);
}

.menuOptions span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.1s;
}

.menuOptions span:before {
  content: '\002F\002F';
  position: absolute;
  opacity: 0;
  top: 0;
  left: -20px;
  transition: 0.1s;
}

.menuOptions:hover span {
  padding-left: 25px;
}

.menuOptions:hover span:before {
  opacity: 1;
  left: 0;
}

@media (max-width: 775px) {
  .menuOptions {
    height: 12vh;
  }
}