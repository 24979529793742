.headerStyle {
  border: solid black 0.5px;
  margin-bottom: 5px;
  margin-left: 10px;
  margin-right: 10px;
  color: black;
  background-color: white;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

.headerStyle:hover {
  background-color: black;
  color: white;
  cursor: pointer;
  transition: .3s;
  filter: drop-shadow(2px 2px 2px grey);
}

.headerStyle:hover > h4 {
  color: white;
}