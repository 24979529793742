.aboutMeHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: solid black .5px;
  margin: 10px;
  padding-left: 20px;
  padding-right: 20px;
  height: 60px;
  background: white;
  overflow: hidden;
}

.aboutMeHeader > div:nth-child(1) {
  position: relative;
  animation: animate 1s;
  animation-fill-mode: backwards;
}

.aboutMeHeader > div:nth-child(2) {
  position: relative;
  animation: animate 1.25s;
  animation-fill-mode: backwards;
}

.aboutMeHeader > div:nth-child(3) {
  position: relative;
  animation: animate 1.5s;
  animation-fill-mode: backwards;
}

.aboutMeHeader > div:nth-child(4) {
  position: relative;
  animation: animate 1.75s;
  animation-fill-mode: backwards;
}

.aboutMeHeader > div:nth-child(5) {
  position: relative;
  animation: animate 2s;
  animation-fill-mode: backwards;
}

.aboutMeHeader > div:nth-child(6) {
  position: relative;
  animation: animate 2.25s;
  animation-fill-mode: backwards;
}

.aboutMeHeader > div:nth-child(7) {
  position: relative;
  animation: animate 2.5s;
  animation-fill-mode: backwards;
}

@keyframes animate{
  0%{
    bottom: -100px;
  }
  100%{
    bottom: 0;
  }
}

.aboutMeBody {
  border: solid black .5px;
  margin-left: 10px;
  margin-right: 10px;
  display: flex;
  min-height: 75vh;
  background: white;
  
}

.aboutMeHero {
  border-right: solid black .5px;
  flex: .9;
  background: white;
  padding-left: 10px;
}

.aboutMeHeroHeader {
  display: flex;
  flex-wrap: wrap;
  font-size: 2.5em;
}

.aboutMeHeroBody {
  display: flex;
  flex-wrap: wrap;
  padding-right: 200px;
  font-size: 1.75em;
}

.aboutMeHeroBody > div {
  margin: 5px;
  letter-spacing: 1px;
  word-spacing: 5px;
  line-height: 1.3;
}

.aboutMeNumber {
  border: solid black .5px;
  background-color: white;
  max-height: 30px;
  min-width: 30px;
  margin-top: 30px;
  margin-left: -16px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}

.aboutMeSideBar{
  flex: .1;
}

@media (max-width: 700px) {
  .aboutMeHeroBody{
    padding-right: 10px;
    font-size: 1.25em;
  }
}