.footerNavOption {
  border-right: solid black .25px;
  border-left: solid black .25px;
  border-bottom: solid black .5px;
  flex: .2;
  height: 20px;
  padding-top: 5px;
  display: flex;
  justify-content: center;
  color: black;
  text-decoration: none;
}

.footerNavOption:hover {
  background-color: black;
  color: white;
  cursor: pointer;
}

.footerNavOption span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.3s;
}

.footerNavOption span:before {
  content: '\002F\002F';
  position: absolute;
  opacity: 0;
  top: 0;
  left: -20px;
  transition: 0.3s;
}

.footerNavOption:hover span {
  padding-left: 25px;
}

.footerNavOption:hover span:before {
  opacity: 1;
  left: 0;
}