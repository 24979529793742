.footerNav {
  display: flex;
  margin-left: 10px;
  margin-right: 10px;
  margin-bottom: 2px;

}

.mobileFooterNav {
  display: none;
}

@media (max-width: 900px){
  .footerNav{
   display: none;
  }
  .mobileFooterNav {
    display: contents;
  }
}